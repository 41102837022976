module.exports = [
  {
    locale: 'nl',
    label: 'Nederlands',
    default: true,
  },
  {
    locale: 'en',
    label: 'English',
  },
  {
    locale: 'zh',
    label: '中文 (Chinese)',
  },
]
